import { getExternalLinkUrl } from '@outlier-org/lst-auth-react'

export const DASHBOARD_VOUCHER_URL = getExternalLinkUrl('https://dashboard.outlier.org/?flag-voucher=true#/account/voucher')
export const AMAZON_RELATIONSHIP = 'Amazon'
export const GUILD_RELATIONSHIP = 'Guild'
export const VOUCHER_TYPE = {
  AMAZON: 'amazon'
}

export const POPUP_TYPE = {
  REMOVABLE: 'REMOVABLE',
  IRREMOVABLE: 'IRREMOVABLE'
}

export const VOUCHER_STATUS = {
  OUTSTANDING_VOUCHER: 'Outstanding Voucher',
  PENDING_APPROVAL: 'Pending Approval',
  APPROVED_VOUCHER: 'Approved Voucher'
}

// Days until drop deadline a student can dismiss the upload voucher popup, Past it cannot be dismissed.
export const DISMISS_DAYS = 17
