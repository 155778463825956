import React, { useEffect } from 'react'
import { useAuthStore, useUserStore } from '@outlier-org/lst-auth-react'
import {
  FlexContainer,
  LoginCard,
  LoginCardHeading,
  LogoutCardHeading,
  LoggedInUserMessage,
  LoginCardHeaderImage,
  LoginCardSubHeading,
  RealizeLoginButton,
  RealizeLogo,
  OutlierAuth0Button,
  LoginButtonDivider
  , LogoutCard
} from './style'
import realizeImage from '../../assets/images/realize-savvas.svg'
import { history } from '../HistoryManager/HistoryManager'

const Login = () => {
  const [setIsSso, login, isAuthenticated, logout] = useAuthStore((state) => [
    state.setIsSso,
    state.login,
    state.isAuthenticated,
    state.logout
  ])

  const user = useUserStore((state) => state.user)

  const loginSso = () => {
    setIsSso(true)
    login()
  }

  const loginAuth0 = () => {
    setIsSso(false)
    login()
  }

  useEffect(() => {
    if (isAuthenticated) {
      history.push('/')
    }
  }, [isAuthenticated])

  return (
    <FlexContainer>
      {
        !isAuthenticated && (
          <LoginCard>
            <LoginCardHeaderImage
              src='https://i.imgur.com/vJowpL1.png'
              alt='Outlier'
            />
            <LoginCardHeading>Welcome</LoginCardHeading>
            <LoginCardSubHeading>Login to continue to Outlier</LoginCardSubHeading>
            <OutlierAuth0Button onClick={() => loginAuth0()}>
              Continue with Auth0
            </OutlierAuth0Button>
            <LoginButtonDivider>or</LoginButtonDivider>
            <RealizeLoginButton onClick={() => loginSso()}>
              <RealizeLogo src={realizeImage} alt='realize-logo' />
              <span>Continue with Realize</span>
            </RealizeLoginButton>
          </LoginCard>
        )
      }

      {
        isAuthenticated && (
          <LogoutCard>
            <LogoutCardHeading>Hi {user.preferred_username || user.email}</LogoutCardHeading>
            <LoggedInUserMessage>You're already logged in.</LoggedInUserMessage>
            <OutlierAuth0Button onClick={logout}>
                Logout!
            </OutlierAuth0Button>
          </LogoutCard>
        )
      }
    </FlexContainer>
  )
}
export default Login
